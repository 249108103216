import { postData } from "./requests";

export const getMatchesForJob = (_job_id, _filter) => {
    const url = 'common/matchesV2';
    const requestData = {
        'job_id': _job_id,
        'filter': _filter
    };
    return postData(url, requestData)
}

export const getMatchesForSeeker = (_seeker_id, _filter) => {
    const url = 'common/seeker-matches';
    const requestData = {
        'seeker_id': _seeker_id,
        'filter': _filter
    };
    return postData(url, requestData)
}

export const reProcessSeekerMatches = (_seeker_id) => {
    const url = 'common/seeker-matches/re-process';
    const requestData = {
        'user_id': _seeker_id
    };
    return postData(url, requestData)
}

export const reProcessPosterMatches = (_poster_id) => {
    const url = 'common/poster-matches/re-process';
    const requestData = {
        'poster_id': _poster_id
    };
    return postData(url, requestData)
}