import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAssociatedData as getAssociatedDataApi } from "../../../services/JobController";
import { getMatchesForSeeker as getMatchesForSeekerApi } from "../../../api/private/matches";
import { downloadSeekerCvByLink as downloadSeekerCvApi } from "../../../api/private/seakers";
import { reProcessSeekerMatches as reProcessSeekerMatchesApi } from "../../../api/private/matches";
import { getFileTypeFromUrl } from "../../../utils/shared-funcs";

import user from "./../../../images/user.jpg";

const Seeker = () => {
const baseDownloadCvText = 'Resume'
const baseReRunMatchesText = 'Re-Run Matches'
const sessionData = localStorage.getItem("myContact");
const data = JSON.parse(sessionData);
// console.log('data: ', data)
const [detail, setDetail] = useState(null);
const [selectedTab, setSelectedTab] = useState('details');
const [matches, setMatches] = useState([])
const [loadingMatches, setLoadingMatches] = useState(false)
const [downloadCvText, setDownloadCvText] = useState(baseDownloadCvText)
const [reRunMatchesText, setReRunMatchesText] = useState(baseReRunMatchesText)

useEffect(() => {
  getData();
  loadMatches()
}, []);

const getData = () => {
  getAssociatedDataApi(data._id, data.usertype).then((_resp) => {
    const data = _resp.data.result
    if(data.length > 0){
      setDetail(data[0]);
    }else{
      alert('No Data Found for Seeker')
    }
    
  }).catch((_resp) => {

  })
};

function loadMatches(){
  setLoadingMatches(true)
  getMatchesForSeekerApi(data._id, '').then((_resp) => {
    const records = _resp.data['matches']
    setMatches(records)
    setMatches(records)
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {
    setLoadingMatches(false)
  })
}

const selfAssessment = () => {
  if (detail) {
    if (detail.selfAssessmentResult && detail.selfAssessmentResult != "") {
      return detail.selfAssessmentResult;
    } else {
      return "";
    }
  }
};

const featurePoint = (val) => {
  if (detail) {
    let feature;
    switch (val) {
      case "jobtitle":
        feature = detail.jobtitle ? detail.jobtitle[0]?.option : "";
        break;
      case "industry":
        feature = detail.industry ? detail.industry[0]?.option : "";
        break;
      case "education":
        feature = detail.education ? detail.education[0]?.option : "";
        break;
      case "location":
        feature = detail.locationName ? `${detail.locationName}` : "";
        break;
      case "role":
        feature = detail.role ? detail.role[0]?.option : "";
        break;
      case "comapnySize":
        feature = detail.campanySize ? detail.campanySize : "";
        break;
      case "employmentType":
        feature = detail.employmentType ? detail.employmentType : "";
        break;
      case "employmentMode":
        feature = detail.employmentMode ? detail.employmentMode : "";
        break;
      case "skillLevel":
        feature = detail?.skilllevel ? detail.skilllevel[0]?.option : "";
        break;
    }
    // console.log("sadasdas", detail.jobtitle[0]?.option);
    return feature;
  } else {
    return "";
  }
};

function onMatchOpenClick(_match){
  const job_id = _match['jobId']
  window.open(`/job-details/${job_id}/job-details`, '_blank');
}

function downloadSeekerCv() {
  const link = data['documentPath'];
  setDownloadCvText('Downloading...')
  downloadSeekerCvApi(link).then((_resp) => {
    const file_data = _resp.data;

    const fileType = getFileTypeFromUrl(link)

    const blob = new Blob([file_data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `seeker_cv.${fileType}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }).catch((_resp) => {
    console.error(_resp);
  }).finally(() => {
    setDownloadCvText(baseDownloadCvText)
  });
}

function onReRunMatchesClick(){
  setReRunMatchesText('Processing...')
  reProcessSeekerMatchesApi(data['_id']).then((_resp) => {
    if(_resp.data.Result === 'OK'){
      alert('Run')
    }else{
      console.info(_resp)
    }
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {
    setReRunMatchesText(baseReRunMatchesText)
  })
}

return (
  <>
    <div className="d-flex align-items-center mb-4">
      <h4 className="fs-20 font-w600 mb-0 me-auto">User Profile</h4>
    </div>
    {detail !== null?
      <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card p-1">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button onClick={() => setSelectedTab('details')} className={`nav-link ${selectedTab === 'details' ? 'active' : ''}`} id="home-tab" localData-bs-toggle="tab" 
                  localData-bs-target="#home-tab-pane" type="button" role="tab" >
                  Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button onClick={() => setSelectedTab('matches')} className={`nav-link ${selectedTab === 'matches' ? 'active' : ''}`} id="profile-tab" localData-bs-toggle="tab" 
                  localData-bs-target="#profile-tab-pane" type="button" role="tab" >
                  Matches
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              {selectedTab === 'details' ?
                <>
                  <div className="card-header border-0 flex-wrap align-items-start">
                    <div className="col-md-9">
                      <div className="user d-sm-flex d-block pe-md-5 pe-0">
                        <img style={{'objectFit':'cover'}} src={data.imagePath ? data.imagePath : user} alt="" />
                        <div className="ms-sm-3 ms-0 me-md-5 md-0">
                          <h5 className="mb-1 font-w600">
                            <Link to={"#"}>{data.fullName ? data.fullName : ""}</Link>
                          </h5>
                          <div className="listline-wrapper mb-2">
                            <span className="item">
                              <i className="far fa-envelope"></i>
                              {data.email ? data.email : ""}
                            </span>
                            <span className="item">
                              <i className="fas fa-map-marker-alt"></i>
                              {data.location ? data.location : ""}
                            </span>
                            <span className="item">
                              <i className="fas fa-mobile"></i>
                              {data.contactNumber ? data.contactNumber : ""}
                            </span>
                          </div>
                          <p>{data.desc ? data.desc : ""}</p>
                          <p>{data.companyDesc ? data.companyDesc : ""}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 align-right">
                      {data.isPaid ? (
                        <h4>
                          {" "}
                          <i
                            class="fa fa-check text-secondary"
                            aria-hidden="true"
                          ></i>{" "}
                          Paid User
                        </h4>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <h4 className="fs-20">Description</h4>
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Name : </span>
                          <span className="font-w400">
                            {data.fullName ? data.fullName : ""}
                          </span>
                        </p>
                        {/* <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Resume Title : </span><span className="font-w400">Searching For PHP Doveloper</span></p> */}
                        {data.companyName ? (
                          <p className="font-w600 mb-2 d-flex">
                            <span className="custom-label-2">Company : </span>
                            <span className="font-w400">{data.companyName}</span>
                          </p>
                        ) : (
                          // <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Company size : </span><span className="font-w400">123</span></p>
                          ""
                        )}

                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Job Title : </span>
                          <span className="font-w400">
                            {featurePoint("jobtitle")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Role : </span>
                          <span className="font-w400">{featurePoint("role")}</span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Industry :</span>{" "}
                          <span className="font-w400">
                            {featurePoint("industry")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Education : </span>
                          <span className="font-w400">
                            {featurePoint("education")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Skill Level : </span>
                          <span className="font-w400">
                            {featurePoint("skillLevel")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Hard Skill: </span>
                          <span className="font-w400">
                            <ul>
                              {detail['hardSkill'].map((sk, _index) => 
                                <li key={sk['id']}>{sk['skill']['option']}</li>
                              )}
                            </ul>
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Location: </span>
                          <span className="font-w400">
                            {featurePoint("location")}
                          </span>
                        </p>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Email ID :</span>{" "}
                          <span className="font-w400">
                            {data.email ? data.email : ""}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Phone number : </span>
                          <span className="font-w400">
                            {data.contactNumber ? data.contactNumber : ""}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Company Size :</span>{" "}
                          <span className="font-w400">
                            {featurePoint("comapnySize")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Employment Type : </span>
                          <span className="font-w400">
                            {featurePoint("employmentType")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Employment Mode :</span>{" "}
                          <span className="font-w400">
                            {featurePoint("employmentMode")}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Soft Skill: </span>
                          <span className="font-w400">
                            <ul>
                              {detail['softSkill'].map((sk, _index) => 
                                <li key={sk['id']}>{sk['skill']['option']}</li>
                              )}
                            </ul>
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Personality: </span>
                          <span className="font-w400">{selfAssessment()}</span>
                        </p>

                        {data.document ? (
                          <p className="font-w600 mb-2 d-flex">
                            <span className="custom-label-2">CV: </span>
                            <a onClick={downloadSeekerCv} disabled={downloadCvText !== baseDownloadCvText} type={'button'} className="font-w400 text-primary" target="_blank">
                              {downloadCvText}
                            </a>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                      <button onClick={() => {onReRunMatchesClick()}} disabled={reRunMatchesText !== baseReRunMatchesText} className="btn btn-outline-primary float-start">{reRunMatchesText}</button>
                  </div>
                  <div className="card-footer d-flex flex-wrap justify-content-between">
                    {data.videoPath && data.videoResume ? (
                      <video width="350" height="200" controls>
                        <source src={`${data.videoPath}`} type="video/mp4" />
                      </video>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              : null}
              {selectedTab === 'matches' ?
                <>
                  {loadingMatches === false?
                    <>
                      {matches.length > 0?
                        <div className="table-responsive">
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>Company</th>
                                {/* <th>Location</th> */}
                                <th>Match %</th>
                                <th>Created On</th>
                                <th>CMD</th>
                              </tr>
                            </thead>
                            <tbody>
                              {matches.map((match) => {
                                try {
                                  return (
                                    <tr key={match['_id']}>
                                      <td>{match['info']['companyDesc']}</td>
                                      {/* <td>{match['info']['location']['city']} - {match['info']['location']['country']}</td> */}
                                      <td>{match['percentage'].toFixed(2)} %</td>
                                      <td>{match['createdAt'].substring(0, 16)}</td>
                                      <td>
                                        <span onClick={() => onMatchOpenClick(match)} className="text-info" type='button'>
                                          Open
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                } catch (error) {
                                  console.error("Error rendering row:", error);
                                  return null;
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      : <h1 className="text-center opacity-60">No Matches Found</h1>}
                    </>
                    : <h1 className="text-center opacity-60">Loading...</h1>
                  }
                </>
              : null}
            </div>
          </div>
        </div>
      </div>
      </>
    : null}
  </>
)};
export default Seeker;
