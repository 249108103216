import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getAssociatedData as getAssociatedDataApi,
  getCreditHistory as getCreditHistoryApi,
} from "../../../services/JobController";
import { reProcessPosterMatches as reProcessPosterMatchesApi } from "../../../api/private/matches";
import UserDetailsModal from "../modals/user-details";
import JobDetailsModal from "../modals/job-details";
import JobMatchesModal from "../modals/job-matches";

import user from "./../../../images/user.jpg";

const Poster = () => {
  const sessionData = localStorage.getItem("myContact");
  const data = JSON.parse(sessionData);
  const [localData, setLocalData] = useState(data)
  const baseReRunMatchesText = 'Re-Run Matches'
  // console.log('data: ', data)
  // const [detail, setDetail] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [creditHistory, setCreditHistory] = useState([]);
  const [openCreditHistory, setOpenCreditHistory] = useState(false);
  const [selectedTab, setSelectedTab] = useState('details');
  const [showUserDetailsModal, setShowDetailsModal] = useState(false)
  const [showJobDetailsModal, setShowJobDetailsModal] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [loadingJobs, setLoadingJobs] = useState(false)
  const [showJobMatchesModal, setShowJobMatchesModal] = useState(false)
  const [reRunMatchesText, setReRunMatchesText] = useState(baseReRunMatchesText)

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const tmpFilteredJobs = filter 
      ? jobs.filter(job => job['jobTitle']['option'].toLowerCase().includes(filter.toLowerCase())) 
      : jobs;
    setFilteredJobs(tmpFilteredJobs);
  }, [filter, jobs]);

  function getData() {
    getAssociatedData();
    getCreditHistory();
  };

  function getAssociatedData() {
    setLoadingJobs(true)
    getAssociatedDataApi(localData?._id).then((_resp) => {
      setJobs(_resp.data.result);
    }).catch((_resp) => {
      console.error(_resp);
    }).finally(() => {
      setLoadingJobs(false)
    });
  }

  function getCreditHistory() {
    getCreditHistoryApi(localData?._id).then((_resp) => {
      setCreditHistory(_resp?.localData?.localData);
    }).catch((_resp) => {
      console.error(_resp);
    });
  }

  function onUserDetailsModalResp(_cmd, _value){
    if(_cmd === 'close'){
      setShowDetailsModal(false)
    }
  }

  function onJobDetailsModal(_cmd, _value){
    if(_cmd === 'close'){
      setShowJobDetailsModal(false)
    }
  }

  function onJobMatchesModalResp(_cmd, _value){
    if(_cmd === 'close'){
      setShowJobMatchesModal(false)
    }
  }

  function onReRunMatchesClick(_job_id){
    setReRunMatchesText('Processing...')
    reProcessPosterMatchesApi(_job_id).then((_resp) => {
      if(_resp.data.Result === 'OK'){
        alert('Run')
      }else{
        console.info(_resp)
      }
    }).catch((_resp) => {
      console.error(_resp)
    }).finally(() => {
      setReRunMatchesText(baseReRunMatchesText)
    })
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="fs-20 font-w600 mb-0 me-auto">User Profile</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button onClick={() => setSelectedTab('details')} className={`nav-link ${selectedTab === 'details' ? 'active' : ''}`} id="home-tab" localData-bs-toggle="tab" 
                  localData-bs-target="#home-tab-pane" type="button" role="tab" >
                  Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button onClick={() => setSelectedTab('jobs')} className={`nav-link ${selectedTab === 'jobs' ? 'active' : ''}`} id="profile-tab" localData-bs-toggle="tab" 
                  localData-bs-target="#profile-tab-pane" type="button" role="tab" >
                  Jobs
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              {selectedTab === 'details' ?
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel">
                  <div className="card-header border-0 flex-wrap align-items-start">
                    <div className="col-md-8">
                      <div className="user d-sm-flex d-block pe-md-5 pe-0">
                        <img
                          style={{ 'objectFit': 'cover' }}
                          src={localData.destination ? localData.destination : user}
                          alt=""
                        />
                        <div className="ms-sm-3 ms-0 me-md-5 md-0">
                          <h5 className="mb-1 font-w600">
                            <Link to={"#"}>{localData.fullName ? localData.fullName : ""}</Link>
                          </h5>
                          <div className="listline-wrapper mb-2">
                            <span className="item">
                              <i className="far fa-envelope"></i>
                              {localData.email ? localData.email : ""}
                            </span>
                            <span className="item">
                              <i className="fas fa-map-marker-alt"></i>
                              {localData.location ? localData.location : ""}
                            </span>
                            <span className="item">
                              <i className="fas fa-mobile"></i>
                              {localData.contactNumber ? localData.contactNumber : ""}
                            </span>
                          </div>
                          <p>{localData.desc ? localData.desc : ""}</p>
                          <p>{localData.companyDesc ? localData.companyDesc : ""}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {localData.isPaid ? (
                        <h4>
                          <i
                            className="fa fa-check text-secondary"
                            aria-hidden="true"
                          ></i>{" "}
                          Total Remain Credits:{" "}
                          {localData.planCredit - localData.usedCredit
                            ? localData.planCredit - localData.usedCredit
                            : 0}
                        </h4>
                      ) : (
                        ""
                      )}

                      {creditHistory?.length > 0 && (
                        <Button
                          className="btn btn-primary mb-1 ms-1"
                          onClick={() => setOpenCreditHistory(!openCreditHistory)}
                        >
                          {openCreditHistory
                            ? "Hide Credit History"
                            : "Show Credit History"}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <h4 className="fs-20">Description</h4>
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Name : </span>
                          <span className="font-w400">
                            {localData.fullName ? localData.fullName : ""}
                          </span>
                        </p>
                        {localData.companyName ? (
                          <p className="font-w600 mb-2 d-flex">
                            <span className="custom-label-2">Company : </span>
                            <span className="font-w400">{localData.companyName}</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Email ID :</span>{" "}
                          <span className="font-w400">
                            {localData.email ? localData.email : ""}
                          </span>
                        </p>
                        <p className="font-w600 mb-2 d-flex">
                          <span className="custom-label-2">Phone number : </span>
                          <span className="font-w400">
                            {localData.contactNumber ? localData.contactNumber : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={() => {setShowDetailsModal(true)}} className="btn btn-outline-primary float-end">Edit</button>
                    <br />
                  </div>
                  </div>
                </div>
                : null}
              {selectedTab === 'jobs' ?
                <>
                  <div className="ms-4">
                    <div className="mt-1 d-flex justify-content-between align-self-center">
                      <div>
                        <h4 className="mt-2 mb-0 pb-0">Total Jobs: {filteredJobs.length}</h4>
                        <small><i onClick={getAssociatedData} className="mdi mdi-refresh text-info" type={'button'}>Refresh</i></small>
                      </div>
                      <div>
                        <input value={filter} onChange={(e) => setFilter(e.target.value)} className="form-control" type="text" placeholder="Filter jobs by name" />
                      </div>
                    </div>
                    <hr className="mt-0 mb-0" />
                  </div>
                  <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                    {loadingJobs === false?
                      <>
                        {filteredJobs.map((job, index) =>
                          <div key={job['_id']} className="row p-4">
                            <div className="col-12 card" style={{ minHeight: '300px', 'boxShadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
                              <div className="row mt-2">
                                <div className="col-md-4">
                                  <h6>{index + 1}: {job['jobTitle']['option']}</h6>
                                  <ol className="list-group list-group-numbered">
                                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Location</div>
                                      </div>
                                      <input defaultValue={job['locationName']} type="text" className="form-control w-100" placeholder="text" />
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Company Size</div>
                                      </div>
                                      <input defaultValue={job['company'][0]['size']} type="text" className="form-control w-100" placeholder="text" />
                                    </li>
                                    <li className="list-group-item d-flex align-items-center pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Roles: {job['role'].length}</div>
                                        {job['role'].map(role =>
                                          <input key={role['_id']} defaultValue={role['option']} type="text" className="form-control w-100" placeholder="text" />
                                        )}
                                      </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Education: {job['education'].length}</div>
                                        {job['education'].map(edu =>
                                          <input key={edu['_id']} defaultValue={edu['option']} type="text" className="form-control w-100" placeholder="text" />
                                        )}
                                      </div>
                                    </li>
                                    <li className="list-group-item pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Employment Mode</div>
                                      </div>
                                      <input defaultValue={job['employmentMode']} disabled type="text" className="form-control w-100" placeholder="text" />
                                    </li>
                                    <li className="list-group-item pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Employment Type</div>
                                      </div>
                                      <input defaultValue={job['employmentType']} disabled type="text" className="form-control w-100" placeholder="text" />
                                    </li>
                                    <li className="list-group-item pt-1 pb-1">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold text-nowrap">Start Date</div>
                                      </div>
                                      <input defaultValue={job['startDate'].substring(0, 10)} disabled type="text" className="form-control w-100" placeholder="text" />
                                    </li>
                                  </ol>
                                </div>
                                <div className="col-md-4">
                                  <h6>Soft Skills: {job['softSkill'].length}</h6>
                                  <ol className="list-group list-group-numbered">
                                    {job['softSkill'].map(skill =>
                                      <li key={skill['id']} className="list-group-item d-flex justify-content-between align-items-start p-2">
                                        <div className="ms-2 me-auto">
                                          <div className="fw-bold">{skill['skill']['option']}</div>
                                          {skill['skill']['exp']}
                                        </div>
                                        <span className="badge border text-dark">{skill['skill']['explevel']}</span>
                                      </li>
                                    )}
                                  </ol>
                                </div>
                                <div className="col-md-4">
                                  <h6>Hard Skills: {job['hardSkill'].length}</h6>
                                  <ol className="list-group list-group-numbered">
                                    {job['hardSkill'].map(skill =>
                                      <li key={skill['id']} className="list-group-item d-flex justify-content-between align-items-start p-2">
                                        <div className="ms-2 me-auto">
                                          <div className="fw-bold">{skill['skill']['option']}</div>
                                          {skill['skill']['exp']}
                                        </div>
                                        <span className="badge border text-dark">{skill['skill']['explevel']}</span>
                                      </li>
                                    )}
                                  </ol>
                                </div>
                                <div className="col-md-12">
                                  <h6>Job Description</h6>
                                  <textarea value={job['jobDesc']} onChange={(e) => {console.log(e)}} disabled className="form-control" style={{ 'minHeight': '230px' }}></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <span onClick={() => {setSelectedJobId(job['_id']); setShowJobMatchesModal(true)}} type='button'>Load Matches</span>
                                  <hr />
                                  <span onClick={() => {onReRunMatchesClick(job['_id'])}} disabled={reRunMatchesText !== baseReRunMatchesText} className="float-start"  type='button'>{reRunMatchesText}</span>
                                </div>
                                <button onClick={() => {setSelectedJobId(job['_id']); setShowJobDetailsModal(true)}} className="btn btn-outline-primary mt-2 mb-2">Edit</button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    : <h2 className="text-center mt-4 mb-4 opacity-50">Loading...</h2> }
                  </div>
                </>
                : null}
            </div>
          </div>
        </div>
      </div>
      <UserDetailsModal _show={showUserDetailsModal} _userId={localData['_id']} _onResp={onUserDetailsModalResp}/>
      <JobDetailsModal _show={showJobDetailsModal} _jobId={selectedJobId} _onResp={onJobDetailsModal} />
      <JobMatchesModal _show={showJobMatchesModal} _job_id={selectedJobId} _onResp={onJobMatchesModalResp}/>
    </>
  );
};

export default Poster;
