import { useEffect, useState } from "react";
import swal from "sweetalert";
import { getAllPointsWeightsV2 as getAllPointsWeightsV2Api, 
    updatePointsWeightsV2 as updatePointsWeightsV2Api } from "../../../api/private/points_weights_v2"

const PointSystemV2 = () => {
const baseUpdateText = 'Update'
const [mounted, setMounted] = useState(false)
const [updateBtnText, setUpdateBtnText] = useState(baseUpdateText)
const [records, setRecords] = useState([])
const [selectedRecord, setSelectedRecord] = useState(null)
const [selectedTotalPoints, setSelectedTotalPoints] = useState(100)

useEffect(() => {
    if(!mounted){
        setMounted(true)
        loadPoints()
    }
}, [])

useEffect(() => {
    if(selectedRecord === null){return}

    let total = 0
    total = total + parseInt(selectedRecord['job_title'])
    total = total + parseInt(selectedRecord['industry'])
    total = total + parseInt(selectedRecord['company_size'])
    total = total + parseInt(selectedRecord['education_level'])
    total = total + parseInt(selectedRecord['employment_type'])
    total = total + parseInt(selectedRecord['skill_level'])
    total = total + parseInt(selectedRecord['employment_mode'])
    total = total + parseInt(selectedRecord['soft_skills'])
    total = total + parseInt(selectedRecord['hard_skills'])
    total = total + parseInt(selectedRecord['self_assessment'])
    setSelectedTotalPoints(total)
}, [selectedRecord])

function loadPoints(){
    const params = {'st': 'ALL'}
    getAllPointsWeightsV2Api(params).then((_resp) => {
        if(_resp.data.Result === 'OK'){
            setRecords(_resp.data.Records)
        }else{

        }
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
}

function updatePointsRec(){
    if(selectedTotalPoints !== 100){return}
    
    setUpdateBtnText('Updating...')
    updatePointsWeightsV2Api(selectedRecord).then((_resp) => {
        if(_resp.data.Result === 'SUCCESS'){
            swal({title: 'Updated', icon: 'success'});
        }else{
            console.log(_resp)
        }
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        setUpdateBtnText(baseUpdateText)
    })
}

function onKeyValueChange(_key, _value){
    let tmp = {...selectedRecord}
    tmp[_key] = _value
    setSelectedRecord(tmp)
}

return <>
<div className="row">
    <div className="col-4">
        <div className="card">
            <ul className="list-group">
                {records.map((rec, index) => 
                    <li onClick={() => setSelectedRecord(rec)} key={rec['_id']} className="list-group-item" style={{'cursor': 'pointer'}}>{index+1}: {rec['name']}</li>
                )}
            </ul>
        </div>
    </div>
    <div className="col-8">
        {selectedRecord !== null?
            <div className="card p-2">
                <div className="d-flex justify-content-between">
                    <div className="f-flex justify-content-between">
                        <h4 className="ms-2"><span className="opacity-50">Name</span>: <u>{selectedRecord['name']}</u></h4>
                        <div className="form-check form-switch ms-2">
                            <input onChange={() => {onKeyValueChange('active', !selectedRecord['active'])}} checked={selectedRecord['active']} value={selectedRecord['active']} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                <span>{selectedRecord['active'] === true? 'Disable': 'Enable'}</span>
                            </label>
                        </div>
                    </div>
                    <div className="text-bold fs-4">
                        Total Points: <b><span className={`${selectedTotalPoints === 100? 'text-success': 'text-danger'} `}>{selectedTotalPoints}</span></b>
                    </div>
                </div>
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Job Title</b>
                        <input onChange={(e) => {onKeyValueChange('job_title', parseInt(e.target.value))}} value={selectedRecord['job_title']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Industry</b>
                        <input onChange={(e) => {onKeyValueChange('industry', parseInt(e.target.value))}} value={selectedRecord['industry']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Company Size</b>
                        <input onChange={(e) => {onKeyValueChange('company_size', parseInt(e.target.value))}} value={selectedRecord['company_size']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Education Level</b>
                        <input onChange={(e) => {onKeyValueChange('education_level', parseInt(e.target.value))}} value={selectedRecord['education_level']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Employment Type</b>
                        <input onChange={(e) => {onKeyValueChange('employment_type', parseInt(e.target.value))}} value={selectedRecord['employment_type']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Skill Level</b>
                        <input onChange={(e) => {onKeyValueChange('skill_level', parseInt(e.target.value))}} value={selectedRecord['skill_level']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Employment Mode</b>
                        <input onChange={(e) => {onKeyValueChange('employment_mode', parseInt(e.target.value))}} value={selectedRecord['employment_mode']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Soft Skills</b>
                        <input onChange={(e) => {onKeyValueChange('soft_skills', parseInt(e.target.value))}} value={selectedRecord['soft_skills']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Hard Skills</b>
                        <input onChange={(e) => {onKeyValueChange('hard_skills', parseInt(e.target.value))}} value={selectedRecord['hard_skills']} className="form-control border w-50" type="number" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1">
                        <b>Self Assessment</b>
                        <input onChange={(e) => {onKeyValueChange('self_assessment', parseInt(e.target.value))}} value={selectedRecord['self_assessment']} className="form-control border w-50" type="number" />
                    </li>
                </ul>
                <div className="d-flex justify-content-between p-2">
                    <div>
                        <span>{selectedRecord['created_by']['username']}</span>
                        <br />
                        <span>{selectedRecord['created_on']}</span>
                    </div>
                    <div>
                        <button onClick={() => updatePointsRec()} className="btn btn-primary float-end" disabled={updateBtnText !== baseUpdateText}>{updateBtnText}</button>
                    </div>
                </div>
            </div>
        : null}
    </div>
</div>
</>
}

export default PointSystemV2