import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Jobs from "./components/Dashboard/Jobs";
import Applications from "./components/Dashboard/Applications";
import MyProfile from "./components/Dashboard/MyProfile";
import Statistics from "./components/Dashboard/Statistics";
import Companies from "./components/Dashboard/Companies";
import Task from "./components/Dashboard/Task";

//Jobs
import JobLists from "./components/Jobs/JobLists";
import JobRoles from "./components/Jobs/JobRoles";
import JobTitle from "./components/Jobs/JobTitle";
import SoftSkill from "./components/Jobs/SoftSkill";
import HardSkill from "./components/Jobs/HardSkill";
import JobIndustry from "./components/Jobs/JobIndustry";
import JobEducation from "./components/Jobs/JobEducation";
import SkillLevel from "./components/Jobs/SkillLevel";
import CompanySize from "./components/Jobs/CompanySize";
import Salary from "./components/Jobs/Salary";
import SiteAnalytics from "./components/Jobs/SiteAnalytics";
import Location from "./components/Jobs/Location";
import JobView from "./components/Jobs/JobView";
import JobApplication from "./components/Jobs/JobApplication";
import ApplyJob from "./components/Jobs/ApplyJob";
import NewJob from "./components/Jobs/NewJob";
import UserProfile from "./components/Jobs/UserProfile";

//SelfAssessMent
import AddSelfAssessment from "./components/SelfAssessMent/AddSelfAssessment";
import EditSelfAssessment from "./components/SelfAssessMent/EditSelfAssessment";
import ListSelfAssessment from "./components/SelfAssessMent/ListSelfAssessment";
import SubscribePlan from "./components/Plan/SubscribePlan";

//SelfAssessMent
import AddPersonalBuilder from "./components/PersonalBuilder/AddPersonalBuilder";
import EditPersonalBuilder from "./components/PersonalBuilder/EditPersonalBuilder";
import ListPersonalBuilder from "./components/PersonalBuilder/ListPersonalBuilder";

import PosterLists from "./components/Jobs/PosterLists";

// Point System
import PointSystem from "./components/PointSystem/PointSystem";
import PointSystemV2 from "./components/PointSystem/point-system-v2";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import WizardForm from "./components/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

// Administrator
import EditProfile from "./components/Admin/EditProfile";

// detail Pges
import Poster from "./components/DetailPage/Poster";
import Seeker from "./components/DetailPage/Seeker";
import JobDetailsPage from "./components/DetailPage/JobDetails";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "search-jobs", component: Jobs },
    { url: "applications", component: Applications },
    { url: "my-profile", component: MyProfile },
    { url: "statistics", component: Statistics },
    { url: "companies", component: Companies },
    { url: "task", component: Task },

    //Jobs
    { url: "job-list", component: JobLists },
    { url: "poster-list", component: PosterLists },
    { url: "Culture", component: JobRoles },
    { url: "job-title", component: JobTitle },
    { url: "soft-skill", component: SoftSkill },
    { url: "hard-skill", component: HardSkill },
    { url: "industry", component: JobIndustry },
    { url: "education-level", component: JobEducation },
    { url: "skill-level", component: SkillLevel },
    { url: "company-size", component: CompanySize },
    { url: "employment-type", component: Salary },
    { url: "location", component: Location },
    { url: "job-view", component: JobView },
    { url: "site-analytics", component: SiteAnalytics },
    { url: "job-application", component: JobApplication },
    { url: "apply-job", component: ApplyJob },
    { url: "new-job", component: NewJob },
    { url: "user-profile", component: UserProfile },

    // detail Page

    { url: "detail-seeker", component: Seeker },
    { url: "detail-poster", component: Poster },
    { url: "job-details/:jobid/job-details", component: JobDetailsPage },

    // Personality Tests
    { url: "add-self-Assessment", component: AddSelfAssessment },
    { url: "edit-self-Assessment", component: EditSelfAssessment },
    { url: "self-Assessment", component: ListSelfAssessment },

    // Personal Builder
    { url: "add-personal-Builder", component: AddPersonalBuilder },
    { url: "edit-personal-Builder", component: EditPersonalBuilder },
    { url: "list-personal-Builder", component: ListPersonalBuilder },

    { url: "plan", component: SubscribePlan },

    // point system
    { url: "point-system", component: PointSystem },
    { url: "point-system-v2", component: PointSystemV2 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    { url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    { url: "todo", component: Todo },
    { url: "redux-form", component: ReduxForm },
    { url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },
    // administration
    { url: "edit-profile", component: EditProfile },
    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </>
  );
};

export default Markup;
