import { postData } from "./requests";

export const getAllPointsWeightsV2 = (_params) => {
    const url = 'common//points-weights-v2/get';
    const requestData = {
        'st': _params['st']
    };
    return postData(url, requestData)
}

export const updatePointsWeightsV2 = (_record) => {
    const url = 'common//points-weights-v2/update';
    const requestData = {
        'record': _record
    };
    return postData(url, requestData)
}